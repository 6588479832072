import type { NextPage } from 'next'
import Head from 'next/head'
import React, { memo, useEffect, useRef, useState } from 'react'
import Cookies from 'js-cookie'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation } from 'next-i18next'
import { useAppSelector, useAppDispatch } from 'src/app/hooks'
import { useRouter } from 'next/router'
import styles from 'src/assets/styles/04_page/index.module.scss'
import Background from 'src/components/Background/Background'
import InfiniteScroll from 'react-infinite-scroll-component'
import { getSocialProof } from 'src/features/compensation/compensationAPI'
import { setCurrencySymbol } from 'src/features/compensation/compensationSlice'
import { Button, Col, Row } from 'antd'
import Search from 'src/components/Search/Search'
import UploadProject from 'src/components/UploadProject/UploadProject'
import Filters from 'src/components/Filters/Filters'
import CategoriesPreFilter from 'src/components/CategoriesPreFilter/CategoriesPreFilter'
import { setOrder, setRefreshFilters } from 'src/features/filters/filtersSlice'
import { shuffle } from 'src/helpers/utils'
import { ls_currency_code_name } from 'src/static/models/localstorage.names'
import SkeletonCategoryProjects from 'src/components/SkeletonCategoryProjects/SkeletonCategoryProjects'
import SkeletonPreFiltersMobile from 'src/components/SkeletonPreFiltersMobile/SkeletonPreFiltersMobile'
import SkeletonFilters from 'src/components/SkeletonFilters/SkeletonFilters'
import ModalFilter from 'src/components/Filters/ModalFilters/ModalFilters'
import menuOutlined from 'src/assets/img/menuOutlined.png'
import ExitIntentPopup from 'src/components/ExitIntentPopup/ExitIntentPopup'
import WhatsNewBanner from 'src/components/WhatsNewBanner/WhatsNewBanner'
import { setReferralCodeCookie } from 'src/helpers/cookies.helpers'
import PopularFilters from 'src/components/Filters/PopularFilters/PopularFilters'
import SortSelect from 'src/components/SortSelect/SortSelect'
import PageMeta from 'src/components/PageMeta/PageMeta'
import { initializeProjectData, initializeCategories } from 'src/features/projects/actions'
import { applyFilters, handleQueryFilters, orderArray } from 'src/features/filters/actions'
import { fetchPortfolios } from 'src/features/projects/actions'
import { fetchProjectTags } from 'src/features/compensation/actions'
import { handleTrackingSearch } from 'src/features/visitors/actions'
import EmptySpaces from 'src/components/EmptySpaces/EmptySpaces'
import CardRenderer from 'src/components/CardRenderer/CardRenderer'
import { openNotification } from 'src/components/Notification/NotificationService'

const didYouKnowCardsQuantityToShow = 5
const didYouKnowCardsQuantity = 9
const currencyCodeEnv = process.env.CURRENCY_CODE

const getRandomIndex = (max: number) => {
  const randomBuffer = new Uint32Array(1)
  crypto.getRandomValues(randomBuffer)
  return randomBuffer[0] % max
}

const HomePage: NextPage = () => {
  const { t, i18n } = useTranslation()
  const router = useRouter()
  const dispatch = useAppDispatch()

  const { project: projects, reloadProject: reloadProjects } = useAppSelector((state) => state.project)

  const {
    currencies,
    currency_code: currencyCode,
  } = useAppSelector((state) => state.compensation)

  const {
    order,
    sessionLoaded: filtersLoaded,
    queryRead,
    filteredProjects,
    refreshFilters,
  } = useAppSelector((state) => state.filters)

  const { user } = useAppSelector((state) => state.user)

  const { loading } = useAppSelector((state) => state.ui)

  const [projectList, setProjectList] = useState<any[]>([])
  const [portfolioList, setPortfolioList] = useState<any[]>([])
  const [mobileProjectList, setMobileProjectList] = useState<any[]>([])
  const [showMore, setShowMore] = useState<boolean>(false)
  const [inMobile, setInMobile] = useState<boolean>(false)
  const [page, setPage] = useState(0)
  const [totalProjects, setTotalProjects] = useState<number>(1)
  const [remainingCards, setRemainingCards] = useState<number>(0)
  const [width, setWidth] = useState<number>(0)
  const [firstTime, setFirstTime] = useState<boolean>(true)
  const [showTabletFilter, setShowTabletFilter] = useState<boolean>(false)
  const [didYouKnowCards, setDidYouKnowCards] = useState<number[]>([])
  const [stopButtonApplyFilter, setStopButtonApplyFilter] = useState<boolean>(false)
  const buttonApplyFilterRef = useRef(null)
  const filterColumnRef = useRef<HTMLDivElement>(null)

  const queryFilters = async () => {
    dispatch(handleQueryFilters(router.query))
  }

  useEffect(() => {
    if(projects) { dispatch(applyFilters()) }
  }, [queryRead, projects, refreshFilters])

  useEffect(() => {
    queryFilters()

    window.addEventListener('popstate', queryFilters)
    return () => {
      window.removeEventListener('popstate', queryFilters)
    }
  }, [projects])


  useEffect(() => {

    if (loading) {
      const currencyCodeStorage = localStorage.getItem(ls_currency_code_name)
      const selectedCurrencyCode = user?.user?.currency_token_code || currencyCodeStorage || currencyCodeEnv

      const currencyUser = currencies.find((el) => el.code === selectedCurrencyCode)
      dispatch(setCurrencySymbol(currencyUser?.symbol))

      if (router.query.profile) {
        Cookies.set('ct-profile', router.query.profile as string, {
          expires: new Date(Date.now() + 2700000)
        })
      }

      dispatch(initializeProjectData())
      dispatch(initializeCategories())

      dispatch(handleTrackingSearch())

      dispatch(fetchPortfolios()).then(setPortfolioList)
      dispatch(fetchProjectTags())

      if (firstTime) {
        getSocialProof()
          .then((res) => {
            const randomPos = getRandomIndex(5)
            setTimeout(() => { openNotification(res[randomPos], t('notification.title')) }, 15000)
            setFirstTime(false)
          })
          .catch((e) => console.error(e))
      }
    }
  }, [loading, currencyCode])

  function storePathValues(path: string) {
    const storage = sessionStorage
    if (!storage) return
    // Set the previous path as the value of the current path.
    const prevPath = storage.getItem('currentPath')

    if (!storage.getItem('prevPath')) {
      storage.setItem('prevPath', null)
    }
    if (`${path}` !== prevPath) {
      storage.setItem('prevPath', prevPath)
    }
    // Set the current path value by looking at the browser's location object.
    storage.setItem('currentPath', `/results${path}`)
  }

  useEffect(() => {
    const { referral_code } = router.query
    if (referral_code) setReferralCodeCookie(referral_code)
    storePathValues(router.asPath)
  }, [router.asPath, router.query])

  useEffect(() => localStorage.setItem('prevRoute', `${router.asPath}`), [router])

  const handleChangeSort = (value) => {
    dispatch(setOrder(value))
    dispatch(setRefreshFilters(!refreshFilters))
  }

  useEffect(() => {
    if (filtersLoaded) {
      const history = JSON.parse(sessionStorage.getItem('history') || '[]')

      if (!history.includes(router.asPath)) {
        const uniqueHistory = Array.from(new Set([...history, router.asPath]))
        sessionStorage.setItem('history', JSON.stringify(uniqueHistory))
      }
    }

    window.history.pushState(window.location.pathname, 'Title', `/results/${i18n.language}${router.asPath}`)
  }, [router.asPath, filtersLoaded])


  useEffect(() => {
    const ITEMS_PER_PAGE = 18
    const MOBILE_ITEMS = 4

    const handleFilteredProjectsUpdate = (projects: any[]) => {
      if (!projects) return
      setProjectList([]) // reset projectList when filters change
      const newDidYouKnowCards = shuffle(
        Array.from({ length: didYouKnowCardsQuantity }, (_, i) => i + 1)
      ).slice(0, didYouKnowCardsQuantityToShow)

      setTotalProjects(projects.length)
      setMobileProjectList(projects.slice(0, MOBILE_ITEMS))

      if (reloadProjects) return

      const initialProjects = projects.slice(0, ITEMS_PER_PAGE)
      setProjectList(addCardDidYouKnowThat(initialProjects, newDidYouKnowCards))
      setPage(1)
    }
    handleFilteredProjectsUpdate(filteredProjects)
  }, [filteredProjects, refreshFilters])

  const handleLoadCards = () => {
    if (reloadProjects || !filteredProjects) { return }
    const itemsPerPage = 18
    const startIndex = page * itemsPerPage - (didYouKnowCardsQuantityToShow - didYouKnowCards.length)
    const endIndex = startIndex + itemsPerPage

    const uniqueProjects = Array.from(
      new Set(filteredProjects.slice(startIndex, endIndex).map(p => p.id))
    ).map(id => filteredProjects.find(p => p.id === id))

    const newProjects = uniqueProjects.filter(
      project => !projectList.some(item => item.id === project.id)
    )

    setProjectList(addCardDidYouKnowThat([...projectList, ...newProjects]))
    setPage(page + 1)
  }

  const addCardDidYouKnowThat = (list, didCards?) => {
    const newProjectList = [...list]
    const newCardList = didCards ? [...didCards] : [...didYouKnowCards]
    const newCardSaved = []

    let count = didYouKnowCardsQuantityToShow

    for (let i = 1; i < didYouKnowCardsQuantityToShow + 1; i++) {
      if (newProjectList.length >= i * 8 && newCardList.length === count) {
        const completeRows = Math.floor(newProjectList.length / 18)
        const remaining = 18 - (newProjectList.length - completeRows * 18)
        remaining === 18 && newCardSaved.push(newProjectList.pop())
        newProjectList.splice(i * 8 - 1, 0, newCardList.pop())
      }
      count--
    }
    newCardSaved.reverse()
    setDidYouKnowCards(newCardList)

    return newProjectList
  }


  const handleResize = () => {
    setWidth(window.innerWidth)
    setShowTabletFilter(false)
  }
  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [width])

  useEffect(() => {
    const handleScroll = () => {
      if (width >= 1366 || !showTabletFilter) { return }

      const columnBottom = filterColumnRef?.current?.getBoundingClientRect().bottom
      const screenBottom = window.scrollY + window.innerHeight
      const buttonBottom = buttonApplyFilterRef?.current?.getBoundingClientRect().bottom + window.scrollY + 30
      const limit = columnBottom + window.scrollY

      if (!stopButtonApplyFilter && buttonBottom > limit) {
        buttonApplyFilterRef.current.style.position = 'unset'
        setStopButtonApplyFilter(true)
        return
      }

      if (stopButtonApplyFilter && screenBottom <= buttonBottom) {
        buttonApplyFilterRef.current.style.position = 'fixed'
        setStopButtonApplyFilter(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [showTabletFilter, stopButtonApplyFilter])

  const handleRemainingCards = () => {
    let col = showTabletFilter || (width < 980 && width > 694) ? 2 : 3

    const totalCards =
      didYouKnowCardsQuantityToShow -
      didYouKnowCards.length +
      totalProjects

    const completeRows = Math.floor(totalCards / col)
    const remaining = col - (totalCards - completeRows * col)

    setRemainingCards(remaining === col || inMobile ? 0 : remaining)
  }

  useEffect(() => {
    if (width > 694) {
      handleRemainingCards()
      setInMobile(false)
      setShowMore(true)
    } else if (width <= 694 && !inMobile) {
      setInMobile(true)
      setShowMore(false)
    }
  }, [width, filteredProjects, showTabletFilter, didYouKnowCards])


  const getListWidth = () => {
    let listWidth = '960px'

    if (width < 692) {
      listWidth = '360px'
    } else if (showTabletFilter || width < 980) {
      listWidth = '640px'
    }

    return getMaxMinWidth(listWidth)
  }
  const getRowJustify = () => showTabletFilter ? 'space-between' : 'center'

  const getMaxMinWidth = (width: string) => ({ maxWidth: width, minWidth: width })
  const portfoliosAndProjects = orderArray((projectList || []), order).concat(portfolioList || [])
  const mPortfoliosAndProjects = orderArray((mobileProjectList || []), order).concat(portfolioList || [])
  const showTabletFilterText = showTabletFilter ? ' Close filters' : ' Filters'
  return (
    <div data-testid='index-page-test' style={{ width: '100%' }}>
      <Head>
        <title>{t('seo.ogTitle')}</title>
        <PageMeta />
      </Head>

      <Row justify='center'>
        <Col span={24}>
          <Background />
          <Search />
        </Col>
      </Row>
      {!inMobile && <div className={styles['container']}>
        <Row justify='center' className={styles['content']}>
          <PopularFilters queryFilters={queryFilters} mobile={inMobile} />
        </Row>
      </div>
      }
      <div className={styles['container']}>
        <Row justify='center' className={styles['content']}>
          <Col>
            <Row wrap={false} justify='space-between' className={styles['mobile-container']}>
              {/* filtros desktop */}
              {width >= 1366 && <Col className={styles['filter-section']}>
                {projects ? <>
                  <Filters changeSort={handleChangeSort} />
                  <WhatsNewBanner banner='FOOTPRINT' />
                  <WhatsNewBanner banner='RFP' />
                </>
                  : <SkeletonFilters />
                }
              </Col>
              }

              {/* grid */}
              <Col className={styles['grid-column']}>
                {/* texto cantidad de proyectos, order y mobile filter */}
                <Row>
                  {projects && !reloadProjects ? <Col>
                    <Row>
                      <Col span={24} className={styles['filter-section-mobile']}>
                        <ModalFilter changeSort={handleChangeSort} />
                        {inMobile && (
                          <PopularFilters queryFilters={queryFilters} mobile={inMobile} />
                        )}
                      </Col>
                    </Row>

                    <Row justify='space-between' className={styles['title-container']} wrap={width < 980}>
                      {/* filtros tablet */}
                      {width < 1366 && width >= 980 && (
                        <Col className={styles['filter-section']}>
                          <div className={styles['iconFilterMobile']}>
                            <img src={menuOutlined.src} className={styles['menuOutlined']}
                              onClick={() => setShowTabletFilter(!showTabletFilter)}
                              alt='menu-icon' />
                            {showTabletFilter ? ' Close filters' : ' Filters'}
                          </div>
                        </Col>
                      )}

                      <h3 className={styles['title']}>
                        {!reloadProjects && (filteredProjects?.length || projects?.length || portfolioList?.length || 0)} {t('titles.projects')}
                      </h3>

                      <SortSelect onChangeSort={handleChangeSort} />
                    </Row>

                    {/* lista de cards */}
                    <Row wrap={false} justify={getRowJustify()}>
                      {/* filtros tablet */}
                      {width < 1366 && <Col ref={filterColumnRef} className={styles['filter-section']} hidden={!showTabletFilter}>
                        {projects ?
                          <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <div>
                              <Filters changeSort={handleChangeSort} />
                              <WhatsNewBanner banner='FOOTPRINT' />
                              <br />
                              <WhatsNewBanner banner='RFP' />
                            </div>
                            <Button ref={buttonApplyFilterRef} className={styles['btnApply']} onClick={() => setShowTabletFilter(false)}>
                              Apply filters
                            </Button>
                          </div>
                          : <SkeletonFilters />
                        }
                      </Col>
                      }
                      <Col style={getListWidth()}>
                        {!reloadProjects && showMore ? <InfiniteScroll
                          dataLength={projectList?.length}
                          next={handleLoadCards}
                          hasMore={projectList?.length <
                            didYouKnowCardsQuantityToShow - didYouKnowCards.length + totalProjects}
                          loader={<SkeletonCategoryProjects />}
                          scrollThreshold={0.71}
                          scrollableTarget='scrollableDiv'
                          className={filteredProjects?.length < 3 ? styles['infinite-container-2'] : styles['infinite-container']}
                        >
                          <Row gutter={[32, 32]} className={styles['mobile-list']} style={getListWidth()}>
                            {portfoliosAndProjects?.map(
                              (p, index) => <CardRenderer item={p} index={index} inMobile={inMobile} key={index} />)}
                            {remainingCards > 0 && projectList?.length >= totalProjects && (
                              <EmptySpaces count={remainingCards} />
                            )}
                          </Row>
                        </InfiniteScroll> : <Row className={styles['mobile-list-show']}>
                          {mPortfoliosAndProjects?.map(
                            (p, index) => <CardRenderer item={p} index={index} inMobile={inMobile} key={index} />)}
                          <button className={styles['showMoreButton']} onClick={() => setShowMore(true)}>
                            {t('category.showMore')}
                          </button>
                        </Row>
                        }
                      </Col>
                    </Row>
                  </Col>
                    : <Row>
                      <Col span={24}>
                        <div>
                          <div className={styles['containerSkeletonDesktop']}>
                            <SkeletonPreFiltersMobile />
                            <SkeletonCategoryProjects />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  }
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <Row justify='center' className={styles['container']}>
        <Col>{projects && <CategoriesPreFilter />}</Col>
      </Row>
      <UploadProject />
      <ExitIntentPopup />
    </div>
  )
}

export async function getStaticProps({ locale }: any) {
  return {
    props: { ...(await serverSideTranslations(locale, ['common'])) }
  }
}

export default memo(HomePage)
